import React from "react";
import { Box } from "@chakra-ui/react";
import { AnimatedContent } from "../components/RouteLink";

import Hero from "../views/HomePage/Hero";
import About from "../views/HomePage/About";
import Philosophy from "../views/common/Philosophy";
import Regulation from "../views/HomePage/Regulation";
import OurNews from "../views/HomePage/OurNews";
import OurTeam from "../views/common/OurTeam";
import Contact from "../views/HomePage/Contact";

function HomePage() {
  return (
    <Box>
      <Hero />
      <AnimatedContent>
        <About />
        <Philosophy desktopHeight="viewHomeSafetyLess" />
        <Regulation />
        <OurNews />
        <OurTeam />
        <Contact />
      </AnimatedContent>
    </Box>
  );
}

export default HomePage;
