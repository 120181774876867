const delayDelta = 0.25;

const cardAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "0.8rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.45,
    delay: 0.5,
  },
};

const cards = Array.from({ length: 3 }).reduce((acc, val, index) => {
  const data = JSON.parse(JSON.stringify(cardAnimation));
  data.transition.delay += index * delayDelta;

  acc[`newsCard${index + 1}`] = data;
  return acc;
}, {});

const buttonAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-12rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.65,
    delay: 0.35,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...cards,
  button: buttonAnimation,
};
