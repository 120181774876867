import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import ScrollBreakpoint from "../../../components/ScrollBreakpoint";
import PageContainer from "../../../components/PageContainer";
import AboutCard from "./AboutCard";
import imgAboutCover from "../../../images/about.jpg";
import animation from "./_animation";

function About() {
  return (
    <ScrollBreakpoint id="about">
      <Box
        minHeight="viewHomeAbout"
        backgroundImage={imgAboutCover}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <PageContainer>
          <Flex
            alignItems="center"
            minHeight="viewHomeAbout"
            paddingY={{ base: "2rem", md: 0 }}
          >
            <ViewportAnimation {...animation}>
              <AboutCard />
            </ViewportAnimation>
          </Flex>
        </PageContainer>
      </Box>
    </ScrollBreakpoint>
  );
}

export default About;
