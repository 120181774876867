const data = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-3rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.85,
  },
};

export default data;
