const containerAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-1.5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.45,
  },
};

const textAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 0.85,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  container: containerAnimation,
  text: textAnimation,
};
