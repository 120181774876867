const image = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-12rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.73,
  },
};

const texts = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "20rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  image: image,
  texts: texts,
};
