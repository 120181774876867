import React from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import ContentTitle from "../../../components/ContentTitle";
import ContactBlock from "../../common/Contact";

function Contact() {
  const { t } = useTranslation();

  return (
    <ContactBlock>
      <Box display={{ base: "none", lg: "block" }}>
        <ContentTitle
          titleBold={t("page@home@contact@title-bold")}
          titleLight={t("page@home@contact@title-light")}
          order="inline"
          titleAlign="left"
          keepOrder={true}
        >
          {t("page@home@contact@text")}
        </ContentTitle>
      </Box>

      <Box display={{ base: "block", lg: "none" }}>
        <ContentTitle
          titleBold={t("page@home@contact@title-bold")}
          titleLight={t("page@home@contact@title-light")}
          order="inline"
          keepOrder={true}
          titleAlign="center"
        >
          {t("page@home@contact@text")}
        </ContentTitle>
      </Box>
    </ContactBlock>
  );
}

export default Contact;
