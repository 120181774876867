import React from "react";
import { RouteLink } from "../../../components/RouteLink";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import CardContainer from "../../../components/CardContainer";
import ContentTitle from "../../../components/ContentTitle";
import ActionButton from "../../../components/ActionButton";

function AboutCard() {
  const { language, t } = useTranslation();

  return (
    <CardContainer backgroundColor="blue.600" maxWidth="viewHomeAboutCard">
      <ContentTitle
        titleBold={t("page@home@about@title-bold")}
        titleLight={t("page@home@about@title-light")}
        order={"ru-RU" === language ? "inline" : "break"}
        color="white"
      >
        <Box lineHeight="2xl">{t("page@home@about@text")}</Box>
      </ContentTitle>

      <Box marginTop="2xl">
        <RouteLink to="/about">
          <ActionButton
            text={t("component@action-button@read-more")}
            color="white"
            withMinWidth={false}
          />
        </RouteLink>
      </Box>
    </CardContainer>
  );
}

export default AboutCard;
