import React from "react";
import { Box, Img, Heading, Text } from "@chakra-ui/react";

function TeamMember({ image, name, description }) {
  return (
    <Box>
      <Box
        overflow="hidden"
        width="viewTeamImageW"
        height="viewTeamImageH"
        boxShadow="0 13px 34px 0 rgba(0,0,0,0.1)"
      >
        <Img
          draggable={false}
          width="100%"
          height="100%"
          objectFit="cover"
          borderRadius="teamImage"
          src={image}
        />
      </Box>

      <Box
        paddingTop="viewTeamMemberTextTop"
        paddingLeft={{ base: 0, md: "viewTeamMemberTextLeft" }}
        maxWidth="viewTeamMemberW"
      >
        <Box marginBottom="xl">
          <Heading fontWeight="bold" fontSize="md" lineHeight="xl">
            {name}
          </Heading>
        </Box>

        <Box>
          <Text fontWeight="light" fontSize="xs" lineHeight="sm">
            {description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default TeamMember;
