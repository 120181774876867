import React, { useState, useRef } from "react";
import { Navigation, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Flex, Img } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useTranslation } from "../../../core/hooks/useTranslation";
import { useAuth } from "../../../core/hooks/useAuth";
import ViewportAnimation from "../../../components/ViewportAnimation";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import TeamMember from "./TeamMember";
import imgLogoSecondary from "../../../images/investment-icon.svg";
import animation from "./_animation";

const members = [
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Nodo Huso",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Kendasha Wood",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
  {
    image:
      "https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
    name: "Jozef Kondratovich",
    description:
      "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
  },
];

function OurTeam() {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(true);

  const { t } = useTranslation();

  const navPrevButton = useRef(null);
  const navNextButton = useRef(null);

  const { isLogged } = useAuth();
  if (!isLogged) {
    return null;
  }

  const onSwipeIndexChange = (Swiper) => {
    setIsStart(Swiper.isBeginning);
    setIsEnd(Swiper.isEnd);
  };

  const onBeforeInit = (Swiper) => {
    if (typeof Swiper.params.navigation !== "boolean") {
      const navigation = Swiper.params.navigation;
      navigation.prevEl = navPrevButton.current;
      navigation.nextEl = navNextButton.current;
    }

    onSwipeIndexChange(Swiper);
  };

  return (
    <Box
      position="relative"
      minHeight="viewTeam"
      marginTop="20"
      paddingTop="20"
      backgroundColor="light.400"
    >
      <Img
        draggable={false}
        src={imgLogoSecondary}
        position="absolute"
        left="50%"
        top="-viewTeamImgTop"
        marginLeft="-viewTeamImgW/2"
        width="viewTeamImgW"
        height="viewTeamImgH"
        filter="grayscale(1)"
        opacity="0.4"
        display={{ base: "none", lg: "block" }}
      />

      <PageContainer>
        <ViewportAnimation {...animation.text}>
          <Flex alignItems="center">
            <Box
              textAlign={{ base: "center", lg: "left" }}
              width={{ base: "100%", lg: "unset" }}
            >
              <ContentTitle titleBold={t("team")} spaces="0" />
            </Box>

            <Box display={{ base: "none", lg: "block" }} marginLeft="3xl">
              <ArrowBackIcon
                ref={navPrevButton}
                width="50px"
                height="50px"
                color={isStart ? "gray.200" : "black"}
                cursor={isStart ? "not-allowed" : "default"}
                _disabled={isStart}
                marginRight="3xl"
              />
              <ArrowForwardIcon
                ref={navNextButton}
                width="50px"
                height="50px"
                color={isEnd ? "gray.200" : "black"}
                cursor={isEnd ? "not-allowed" : "default"}
                _disabled={isEnd}
              />
            </Box>
          </Flex>
        </ViewportAnimation>

        <Box paddingTop="3xl" />

        <ViewportAnimation {...animation.container}>
          <Swiper
            modules={[Navigation, FreeMode]}
            navigation={true}
            freeMode={true}
            spaceBetween={5}
            slidesPerView={3}
            onBeforeInit={onBeforeInit}
            onActiveIndexChange={onSwipeIndexChange}
            onSlideMove={onSwipeIndexChange}
            breakpoints={{
              0: {
                spaceBetween: 5,
                slidesPerView: 1.3,
                centeredSlides: true,
              },
              768: {
                spaceBetween: 5,
                slidesPerView: 2.2,
              },
              992: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
            }}
          >
            {members.map((member, i) => (
              <SwiperSlide key={`member-${i}`}>
                <Box marginY={{ base: "5", lg: "0" }}>
                  <TeamMember {...member} />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <Flex
          justifyContent="space-between"
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          {members.map((member, i) => (
            <Box key={`member-${i}`} marginY={{ base: "5", lg: "0" }}>
              <TeamMember {...member} />
            </Box>
          ))}
        </Flex> */}
        </ViewportAnimation>
      </PageContainer>
    </Box>
  );
}

export default OurTeam;
