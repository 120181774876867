import React from "react";
import { RouteLink } from "../../../components/RouteLink";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import PageParallax from "../../../components/PageParallax";
import ViewportAnimation from "../../../components/ViewportAnimation";
import ScrollBreakpoint from "../../../components/ScrollBreakpoint";
import PageContainer from "../../../components/PageContainer";
import CardContainer from "../../../components/CardContainer";
import ContentTitle from "../../../components/ContentTitle";
import ActionButton from "../../../components/ActionButton";
import imgRegulation from "../../../images/regulation.png";
import animation from "./_animation";

function Regulation() {
  const { t } = useTranslation();

  return (
    <ScrollBreakpoint id="regulation">
      <Box
        position="relative"
        minHeight="viewHomeRegulation"
        paddingTop={{ base: "2xl", lg: "5xl" }}
      >
        <Box
          position="absolute"
          left="0"
          top={{ base: "4xl", lg: "viewHomeRegulationCardColorT" }}
        >
          <PageParallax y={["-30%", "20%"]}>
            <CardContainer spacing={false} borders={["br", "tr"]}>
              <Box
                maxWidth="100vw"
                width={{
                  base: "viewHomeRegulationCardColorWXXSmall",
                  md: "viewHomeRegulationCardColorWXSmall",
                  xl: "viewHomeRegulationCardColorWSmall",
                  "2xl": "viewHomeRegulationCardColorW",
                }}
                height="viewHomeRegulationCardColorH"
                bgGradient="linear(165.2deg, blue.1000 0%, blue.1100 100%)"
              />
            </CardContainer>
          </PageParallax>
        </Box>

        <PageContainer>
          <Box position="relative" minHeight="viewHomeRegulationCardH">
            <ViewportAnimation {...animation.image}>
              <Box
                position={{ base: "", lg: "absolute" }}
                left={{ base: "", lg: "0" }}
                margin={{ base: "0 auto", lg: "0" }}
                width={{
                  base: "viewHomeRegulationCardWSmall",
                  xl: "viewHomeRegulationCardW",
                }}
                height={{
                  base: "viewHomeRegulationCardHSmall",
                  xl: "viewHomeRegulationCardH",
                }}
              >
                <PageParallax y={["30%", "-20%"]}>
                  <CardContainer
                    spacing={false}
                    boxShadow="0 12px 44px 0 rgba(0,0,0,0.05)"
                  >
                    <Box
                      width={{
                        base: "viewHomeRegulationCardWSmall",
                        xl: "viewHomeRegulationCardW",
                      }}
                      height={{
                        base: "viewHomeRegulationCardHSmall",
                        xl: "viewHomeRegulationCardH",
                      }}
                      // background="blue.600"
                      backgroundImage={imgRegulation}
                      backgroundRepeat="no-repeat"
                      backgroundSize="contain"
                    />
                  </CardContainer>
                </PageParallax>
              </Box>
            </ViewportAnimation>

            <ViewportAnimation {...animation.texts}>
              <Box
                position={{ base: "", lg: "absolute" }}
                right={{ base: "", lg: "0" }}
                width={{ base: "", lg: "viewHomeRegulationCardW" }}
                minHeight="viewHomeRegulationCardH"
                paddingTop={{
                  base: "viewHomeRegulationCardPSm",
                  xl: "viewHomeRegulationCardP",
                }}
              >
                <ContentTitle title={t("page@home@regulation@title")}>
                  {t("page@home@regulation@text")}
                </ContentTitle>

                <Box marginTop="2.5xl" maxWidth="8xl" width="100%">
                  <RouteLink to="/regulation">
                    <ActionButton
                      text={t("component@action-button@read-more")}
                      color={{
                        dot: "blue.100",
                        text: "black",
                        arrow: "black",
                      }}
                    />
                  </RouteLink>
                </Box>
              </Box>
            </ViewportAnimation>
          </Box>
        </PageContainer>
      </Box>
    </ScrollBreakpoint>
  );
}

export default Regulation;
