import React, { useMemo } from "react";
import { Link } from "gatsby";
import { RouteLink } from "../../../components/RouteLink";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import ViewportAnimation from "../../../components/ViewportAnimation";
import ScrollBreakpoint from "../../../components/ScrollBreakpoint";
import PageContainer from "../../../components/PageContainer";
import ContentTitle from "../../../components/ContentTitle";
import NewsCard from "../../../components/NewsCard";
import ActionButton from "../../../components/ActionButton";
import animation from "./_animation";

import newsLoader from "../../../content";

function OurNews() {
  const { language, t } = useTranslation();
  const data = useMemo(() => newsLoader(language), [language]);

  return (
    <ScrollBreakpoint id="news">
      <PageContainer>
        <Box>
          <Flex width="100%" display={{ base: "none", md: "flex" }}>
            <ContentTitle
              titleBold={t("page@home@news@title-bold")}
              titleLight={t("page@home@news@title-light")}
              titleAlign="right"
              order="inline"
              keepOrder={true}
            />
          </Flex>

          <Flex
            width="100%"
            display={{ base: "flex", md: "none" }}
            marginTop={{ base: "3xl", md: "0" }}
          >
            <ContentTitle
              titleBold={t("page@home@news@title-bold")}
              titleLight={t("page@home@news@title-light")}
              titleAlign="center"
              order="inline"
              keepOrder={"hy-AM" !== language}
            />
          </Flex>
        </Box>

        <Box marginTop="cardY">
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={7}
          >
            {data.map((row, i) => (
              <GridItem key={`NewsCard-${i}-${row.id}`}>
                <ViewportAnimation {...animation[`newsCard${i + 1}`]}>
                  <Link to={`/news/${row.id}`}>
                    <NewsCard
                      image={row.articleImage}
                      title={row.articleTitle}
                      text={row.articleDescription}
                      date={row.articleDate}
                    />
                  </Link>
                </ViewportAnimation>
              </GridItem>
            ))}
          </Grid>
        </Box>

        <Box marginTop="2.3xl">
          <Flex width="100%" justifyContent="flex-end">
            <ViewportAnimation {...animation.button}>
              <RouteLink
                to="/news"
                style={{ width: "100%", maxWidth: "420px" }}
              >
                <ActionButton
                  text={t("component@action-button@see-all")}
                  color={{
                    dot: "blue.100",
                    text: "black",
                    arrow: "black",
                  }}
                />
              </RouteLink>
            </ViewportAnimation>
          </Flex>
        </Box>
      </PageContainer>
    </ScrollBreakpoint>
  );
}

export default OurNews;
